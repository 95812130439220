<template>
  <div>
    <h2>Perhitungan Waktu LIVE Result</h2>
    <hr />
    <div v-if="waktu != null" class="p-grid p-mt-3">
      <div class="p-col-8 border" id="header">Definisi</div>
      <div class="p-col-2 border" id="header">Dari</div>
      <div class="p-col-2 border" id="header">Sampai</div>

      <!--Open Prize 1-->
      <div class="p-col-12" id="subheader">Open Prize 1</div>
      <!--Diam-->
      <div class="p-col-8 border">Diam</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>

      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize1_diam_end" />
      </div>

      <!--Loading-->
      <div class="p-col-8 border">Loading</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize1_loading_end" />
      </div>

      <!--Acak-->
      <div class="p-col-8 border">Acak + Result (close prize 1)</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize1_result_end" />
      </div>
      <!--End of Open Prize 1-->

      <!--Open Prize 2-->
      <div class="p-col-12" id="subheader">Open Prize 2</div>
      <!--Diam-->
      <div class="p-col-8 border">Diam</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>

      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize2_diam_end" />
      </div>

      <!--Loading-->
      <div class="p-col-8 border">Loading</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize2_loading_end" />
      </div>

      <!--Acak-->
      <div class="p-col-8 border">Acak + Result (close prize 2)</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize2_result_end" />
      </div>
      <!--End of Open Prize 2-->

      <!--Open Prize 3-->
      <div class="p-col-12" id="subheader">Open Prize 3</div>
      <!--Diam-->
      <div class="p-col-8 border">Diam</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>

      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize3_diam_end" />
      </div>

      <!--Loading-->
      <div class="p-col-8 border">Loading</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize3_loading_end" />
      </div>

      <!--Acak-->
      <div class="p-col-8 border">Acak + Result (close prize 3)</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.prize3_result_end" />
      </div>
      <!--End of Open Prize 3-->

      <!--Open Started Prize-->
      <div class="p-col-12" id="subheader">Open Started Prize</div>
      <!--Diam-->
      <div class="p-col-8 border">Diam</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>

      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.started_diam_end" />
      </div>

      <!--Loading-->
      <div class="p-col-8 border">Loading</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.started_loading_end" />
      </div>

      <!--Acak-->
      <div class="p-col-8 border">Acak + Result (close Started Prize)</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.started_result_end" />
      </div>
      <!--End ofStarted-->

      <!--Open Consolation Prize-->
      <div class="p-col-12" id="subheader">Open Consolation Prize</div>
      <!--Diam-->
      <div class="p-col-8 border">Diam</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>

      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.consolation_diam_end" />
      </div>

      <!--Loading-->
      <div class="p-col-8 border">Loading</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        {{ waktu.standby_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.consolation_loading_end" />
      </div>

      <!--Acak-->
      <div class="p-col-8 border">Acak + Result (close Consolation Prize)</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.consolation_result_end" />
      </div>
      <!--End of Consolation Prize-->

      <div class="p-col-12 p-d-flex p-jc-end border" id="header">
        <Button label="Simpan perubahan" @click="updateWaktu" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { waktu: Object },
  data() {
    return {
      standbyStart: null,
      standbyEnd: null,
      openEnd: null,
      closeEnd: null,
    };
  },
  methods: {
    async updateWaktu() {
      console.log(this.waktu);
      await this.$http
        .put("/time", {
          prize1_diam_end: this.waktu.prize1_diam_end,
          prize1_loading_end: this.waktu.prize1_loading_end,
          prize1_result_end: this.waktu.prize1_result_end,
          prize2_diam_end: this.waktu.prize2_diam_end,
          prize2_loading_end: this.waktu.prize2_loading_end,
          prize2_result_end: this.waktu.prize2_result_end,
          prize3_diam_end: this.waktu.prize3_diam_end,
          prize3_loading_end: this.waktu.prize3_loading_end,
          prize3_result_end: this.waktu.prize3_result_end,
          started_diam_end: this.waktu.started_diam_end,
          started_loading_end: this.waktu.started_loading_end,
          started_result_end: this.waktu.started_result_end,
          consolation_diam_end: this.waktu.consolation_diam_end,
          consolation_loading_end: this.waktu.consolation_loading_end,
          consolation_result_end: this.waktu.consolation_result_end,
        })
        .then((r) => {
          this.$toast.add({
            severity: "info",
            summary: r.statusText,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style scoped>
#header {
  background-color: rgb(88, 138, 14);
  color: white;
  text-align: center;
}
.border {
  border: 1px #aaa solid;
}
</style>