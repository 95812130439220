<template>
  <div>
    <waktu-normal :waktu="waktu" />
    <waktu-live :waktu="waktu" class="p-mt-6" />
    <hr />
    <div>Setting waktu harus sesuai dengan timezone pada server</div>
  </div>
</template>

<script>
import WaktuLive from "./WaktuLive.vue";
import WaktuNormal from "./WaktuNormal.vue";
export default {
  components: { WaktuNormal, WaktuLive },
  data() {
    return {
      waktu: null,
    };
  },
  methods: {
    async getWebTime() {
      await this.$http.get("/time").then((r) => (this.waktu = r.data));
    },
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    this.getWebTime();
  },
};
</script>

<style>
</style>