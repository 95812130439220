<template>
  <div>
    <div class="p-grid">
      <div id="header" class="border p-ai-center p-jc-center p-d-flex p-col-1">
        <div>No</div>
      </div>
      <div id="header" class="border p-ai-center p-jc-center p-d-flex p-col-2">
        Day
      </div>
      <div id="header" class="border p-ai-center p-jc-center p-d-flex p-col-2">
        Date
      </div>
      <div id="header" class="border p-ai-center p-jc-center p-d-flex p-col-2">
        Draw No.
      </div>
      <div id="header" class="border p-ai-center p-jc-center p-d-flex p-col-3">
        1st Prize
      </div>
      <div
        id="header"
        class="border p-ai-center p-d-flex p-flex-column p-jc-center p-col-2 p-p-0"
      >
        <div class="p-py-2">Aksi</div>
        <div class="p-grid p-mt-1 p-jc-around p-fluid" style="width: 100%">
          <div class="border p-col">Edit</div>
          <div class="border p-col">Delete</div>
        </div>
      </div>
    </div>
    <div
      class="p-grid p-text-center p-mt-0"
      v-for="(data, index) in draw_data"
      :key="index"
    >
      <div class="border p-col-1">{{ index + 1 }}</div>
      <div class="border p-col-2">{{ getDay(data.draw_date) }}</div>
      <div class="border p-col-2">{{ data.draw_date }}</div>
      <div class="border p-col-2">{{ data.draw_no }}</div>
      <div class="border p-col-3">{{ data.draw_prize1 }}</div>
      <div class="border p-col-1">
        <router-link :to="'/admin/editResult/' + data.draw_no">
          <Button icon="pi pi-pencil" />
        </router-link>
      </div>
      <div class="border p-col-1">
        <Button icon="pi pi-trash" @click="deleteRow(data)" />
      </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
    <Toast />
  </div>
</template>

<script>
export default {
  data() {
    return { draw_data: null };
  },
  methods: {
    deleteData(id) {
      this.$http.delete("/result/" + id).then((r) => {
        if (r.status == 200) {
          let z = this.draw_data.filter((r) => {
            if (r.draw_no != id) {
              return r;
            }
          });
          this.draw_data = z;
          this.$toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: "Record Deleted",
            life: 3000,
          });
        }
      });
    },
    deleteRow(data) {
      this.$confirm.require({
        message: "Are you sure you want to proceed?",
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.deleteData(data.draw_no);
        },
        reject: () => {
          //callback to execute when user rejects the action
        },
      });
    },
    async getResult() {
      await this.$http.get("/result").then((r) => (this.draw_data = r.data));
    },
    getDay(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    this.getResult();
  },
};
</script>

<style>
</style>