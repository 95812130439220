<template>
  <div>
    <h2>User Administrator</h2>
    <hr />
    <div class="p-grid p-mt-3">
      <div class="p-col-1 border" id="header">No.</div>
      <div class="p-col border" id="header">UserName</div>
      <div class="p-col border" id="header">Password</div>
      <div class="p-col border" id="header">Level</div>
      <div class="p-col border" id="header">Aksi</div>
    </div>
    <hr />
    <!--END OF USER LIST-->

    <div class="p-grid p-mt-3">
      <div class="p-col p-text-center p-py-3 p-text-bold" id="subheader">
        TAMBAH ADMIN
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-3 border" id="header">Username</div>
      <div class="p-col border" id="header">Password</div>
      <div class="p-col-3 border" id="header">Level</div>
    </div>
    <div class="p-grid p-fluid p-jc-center p-py-2">
      <div class="p-col-3 p-field">
        <InputText id="name" v-model="forms.name" type="text" />
      </div>
      <div class="p-col p-field">
        <Password id="name" v-model="forms.password" toggleMask />
      </div>
      <div class="p-col-3">
        <Dropdown
          v-model="selectedLevel"
          :options="levels"
          optionLabel="name"
          placeholder="Pilih Level"
        />
      </div>
    </div>
    <!--END OF TAMBAH ADMIN-->

    <!--HAK AKSES-->
    <div class="p-grid">
      <div class="p-col p-py-2 p-text-bold" id="subheader">HAK AKSES</div>
    </div>
    <div class="p-mt-3">
      <div class="p-d-flex">
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.setting_website" />
          <label>Setting Website</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.setting_waktu" />
          <label>Setting Waktu</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.setting_sidebar" />
          <label>Setting Sidebar</label>
        </div>
      </div>

      <div class="p-d-flex">
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.add_result" />
          <label>Add Result</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.edit_result" />
          <label>Edit Result</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.view_result" />
          <label>View Result</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.view_result_keseluruhan" />
          <label>View Result Keseluruhan</label>
        </div>
      </div>

      <div class="p-d-flex">
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.add_admin" />
          <label>Add Admin</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.edit_admin" />
          <label>Edit Admin</label>
        </div>
        <div class="p-field-checkbox p-mr-3">
          <Checkbox :binary="true" v-model="forms.delete_admin" />
          <label>Delete Admin</label>
        </div>
      </div>
    </div>
    <!--END OF HAK AKSES-->
    <div id="header" class="p-d-flex p-jc-end p-p-2">
      <Button label="TAMBAH" @click="postData()" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLevel: null,
      forms: {
        name: null,
        password: null,
        setting_website: false,
        setting_waktu: false,
        setting_sidebar: false,
        add_result: false,
        edit_result: false,
        view_result: false,
        view_result_keseluruhan: false,
        add_admin: false,
        edit_admin: false,
        delete_admin: false,
      },
      levels: [
        { name: "Super User", code: "SA" },
        { name: "User", code: "US" },
      ],
    };
  },
  methods: {
    postData() {
      console.log(this.forms);
    },
  },
};
</script>

<style>
</style>