<template>
  <div>
    <h2>Tambah Result</h2>
    <div>Harus diisi semua!, dan harus 6digit</div>
    <hr />
    <div class="p-grid p-mt-2">
      <div class="p-col">
        <div class="p-mb-3">Draw No. {{ draw_no }}</div>
        <Button label="Generate 6D" @click="generate6D()" />
        <div class="errMsg" v-show="errNumber">Please Generate Number</div>
      </div>

      <div class="p-col">
        <div class="p-mb-3">Tanggal</div>
        <InputText type="date" v-model="draw_date" @change="errDate = false" />
        <div class="errMsg" v-show="errDate">Please Choose Date</div>
      </div>
    </div>

    <divider />
    <!--prize 1-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-text-center">Prize 1</div>
      </div>
      <div class="p-col">
        <InputText v-model="draw_prize1" class="p-text-center" />
      </div>
    </div>

    <!--prize 2-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-text-center">Prize 2</div>
      </div>
      <div class="p-col">
        <InputText v-model="draw_prize2" class="p-text-center" />
      </div>
    </div>

    <!--prize 3-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-jc-center p-ai-center p-text-center">
          <div>Prize 3</div>
        </div>
      </div>
      <div class="p-col">
        <InputText v-model="draw_prize3" class="p-text-center" />
      </div>
    </div>

    <!--Started-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center p-ai-center">Started</div>
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_started1" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_started2" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_started3" class="p-text-center" />
      </div>

      <div class="p-col-4">
        <InputText v-model="draw_started4" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_started5" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_started6" class="p-text-center" />
      </div>
    </div>

    <!--consolation-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center">Consolation</div>
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_consolation1" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_consolation2" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_consolation3" class="p-text-center" />
      </div>

      <div class="p-col-4">
        <InputText v-model="draw_consolation4" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_consolation5" class="p-text-center" />
      </div>
      <div class="p-col-4">
        <InputText v-model="draw_consolation6" class="p-text-center" />
      </div>
    </div>
    <div class="p-d-flex p-jc-center">
      <Button label="UPDATE" @click="postData()" />
    </div>

    <Dialog header="Success" :visible.sync="display"
      >Data berhasil dibuat.</Dialog
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      display: false,
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      sixDigit: [],
      errDate: false,
      errNumber: false,
    };
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    this.getRunning();
  },
  methods: {
    getHari(tanggal) {
      var hari = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var dt = new Date(tanggal);
      return hari[dt.getDay()];
    },
    async postData() {
      let flag = false;
      let hari = "";
      if (this.draw_date == "") {
        // console.log(this.draw_date)
        // return
        this.errDate = true;
        flag = true;
      } else {
        hari = this.getHari(this.draw_date);
      }
      if (this.draw_prize1 == "") {
        this.errNumber = true;
        flag = true;
      } else {
        this.errNumber = false;
      }
      if (flag) {
        return;
      }
      await this.$http
        .post("/result", {
          draw_no: this.draw_no,
          draw_date: this.draw_date,
          draw_date_name: hari,
          draw_prize1: this.draw_prize1,
          draw_prize2: this.draw_prize2,
          draw_prize3: this.draw_prize3,
          draw_started1: this.draw_started1,
          draw_started2: this.draw_started2,
          draw_started3: this.draw_started3,
          draw_started4: this.draw_started4,
          draw_started5: this.draw_started5,
          draw_started6: this.draw_started6,
          draw_consolation1: this.draw_consolation1,
          draw_consolation2: this.draw_consolation2,
          draw_consolation3: this.draw_consolation3,
          draw_consolation4: this.draw_consolation4,
          draw_consolation5: this.draw_consolation5,
          draw_consolation6: this.draw_consolation6,
        })
        .then((r) => {
          if (r.status == 201) {
            this.$router.push({ path: "/admin/dataResult" });
            this.display = true;
            this.getRunning();
            // this.clean();
          }
        });
    },
    clean() {
      this.draw_prize1 = "";
      this.draw_prize2 = "";
      this.draw_prize3 = "";
      this.draw_started1 = "";
      this.draw_started2 = "";
      this.draw_started3 = "";
      this.draw_started4 = "";
      this.draw_started5 = "";
      this.draw_started6 = "";
      this.draw_consolation1 = "";
      this.draw_consolation2 = "";
      this.draw_consolation3 = "";
      this.draw_consolation4 = "";
      this.draw_consolation5 = "";
      this.draw_consolation6 = "";
    },
    async getRunning() {
      await this.$http.get("/getNo").then((r) => {
        this.draw_no = r.data;
      });
    },
    generate6D() {
      this.errNumber = false;
      this.draw_prize1 = this.generateNumber();
      this.draw_prize2 = this.generateNumber();
      this.draw_prize3 = this.generateNumber();
      this.draw_started1 = this.generateNumber();
      this.draw_started2 = this.generateNumber();
      this.draw_started3 = this.generateNumber();
      this.draw_started4 = this.generateNumber();
      this.draw_started5 = this.generateNumber();
      this.draw_started6 = this.generateNumber();
      this.draw_consolation1 = this.generateNumber();
      this.draw_consolation2 = this.generateNumber();
      this.draw_consolation3 = this.generateNumber();
      this.draw_consolation4 = this.generateNumber();
      this.draw_consolation5 = this.generateNumber();
      this.draw_consolation6 = this.generateNumber();
    },
    generateNumber() {
      var flag = true;
      while (flag) {
        var rnd = Math.floor(Math.random() * 999999).toString();
        rnd = this.maskNumber(rnd);
        if (!this.sixDigit.includes(rnd)) {
          flag = false;
          this.sixDigit.push(rnd);
          return rnd;
        }
      }
    },
    maskNumber(val) {
      var minus = 6 - val.length;
      var zero = "";
      for (let i = 0; i < minus; i++) {
        zero += "0";
      }
      return zero + val;
    },
  },
};
</script>

<style>
</style>