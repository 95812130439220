<template>
  <div>
    <h2>Perhitungan Waktu NORMAL</h2>
    <hr />
    <div class="p-grid p-mt-3" v-if="waktu != null">
      <div class="p-col-8 border" id="header">Definisi</div>
      <div class="p-col-2 border" id="header">Dari</div>
      <div class="p-col-2 border" id="header">Sampai</div>

      <!--Standby-->
      <div class="p-col-8 border">Waktu Standby</div>
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.standby_start" />
      </div>
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.standby_end" />
      </div>

      <!--Open-->
      <div class="p-col-8 border">Open LIVE Result</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.standby_end != null"
      >
        <!-- <div class="p-as-center"> -->
        {{ waktu.standby_end }}
        <!-- </div> -->
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.open_end" />
      </div>

      <!--Close-->
      <div class="p-col-8 border">Close LIVE Result</div>
      <div
        class="p-col-2 border p-ai-center p-jc-center p-d-flex"
        v-if="waktu.open_end != null"
      >
        {{ waktu.open_end }}
      </div>
      <div class="p-col-2 border" v-else />
      <div class="p-col-2 border p-d-flex p-jc-center">
        <InputText type="time" v-model="waktu.close_end" />
      </div>

      <div class="p-col-12 p-d-flex p-jc-end border" id="header">
        <Button label="Simpan perubahan" @click="updateWaktu" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { waktu: Object },
  data() {
    return {
      standbyStart: null,
      standbyEnd: null,
      openEnd: null,
      closeEnd: null,
    };
  }, methods: {
    async updateWaktu() {
      await this.$http
        .put("/time", {
        standby_start:this.waktu.standby_start,
        standby_end:this.waktu.standby_end,
        open_end:this.waktu.open_end,
        close_end:this.waktu.close_end,
        })
        .then((r) => {
          this.$toast.add({
            severity: "info",
            summary: r.statusText,
            life: 3000,
          });
        });
    },
  },
};
</script>

<style>
</style>