<template>
  <div>
    <h2>Result</h2>
    <div class="p-grid box p-text-left">
      <div class="p-col">
        <div>Draw No. {{ draw_no }}</div>
      </div>

      <div class="p-col">
        <div>Date : {{ getDate(draw_date) }}</div>
      </div>
    </div>
    <divider />
    <!--prize 1-->
    <div class="p-grid p-fluid p-ai-stretch vertical-container">
      <div class="p-col">
        <div class="box box-stretched p-text-center">Prize 1</div>
      </div>
      <div class="p-col">
        <div class="numberbox">
          <six-number :value="draw_prize1" />
        </div>
      </div>
    </div>

    <!--prize 2-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box box-stretched p-text-center">Prize 2</div>
      </div>
      <div class="p-col">
        <div class="numberbox">
          <six-number :value="draw_prize2" />
        </div>
      </div>
    </div>

    <!--prize 3-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box box-stretched p-jc-center p-ai-center p-text-center">
          <div>Prize 3</div>
        </div>
      </div>
      <div class="p-col">
        <div class="numberbox">
          <six-number :value="draw_prize3" />
        </div>
      </div>
    </div>

    <!--Started-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center p-ai-center">Started</div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started1" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started2" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started3" />
        </div>
      </div>

      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started4" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started5" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_started6" />
        </div>
      </div>
    </div>

    <!--consolation-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center">Consolation</div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation1" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation2" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation3" />
        </div>
      </div>

      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation4" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation5" />
        </div>
      </div>
      <div class="p-md-4 p-col-12">
        <div class="numberbox">
          <six-number :value="draw_consolation6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SixNumber from "./SixNumber.vue";
export default {
  components: { SixNumber },
  methods: {
    async getResult() {
      await this.$http.get("/result").then((r) => (this.draw_data = r.data[0]));
    },
    getDate(data) {
      var dt = new Date(data);

      let options = {
        weekday: "long",
        year: "numeric",
        day: "numeric",
        month: "long",
      };

      return dt.toLocaleString("en-GB", options);
    },
    setResult() {
      let data = this.draw_data;
      this.draw_no = data.draw_no;
      this.draw_date = data.draw_date;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
  },
  data() {
    return {
      draw_no: "",
      draw_date: "",
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      draw_data: [],
    };
  },
  mounted() {
    this.getResult();
  },
  watch: {
    draw_data: function (newVal) {
      if (newVal != null && newVal != undefined) {
        this.setResult();
      }
    },
  },
};
</script>

<style>
</style>