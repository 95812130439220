import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import SetWebsite from "@/components/backoffice/SetWebsite.vue";
import SetWaktu from "@/components/backoffice/SetWaktu.vue";
import SetTambahResult from "@/components/backoffice/SetTambahResult.vue";
import SetResult from "@/components/backoffice/SetResult.vue";
import SetAdmin from "../components/backoffice/SetAdmin.vue";
import SetDataResult from "../components/backoffice/SetDataResult.vue";
import SetEditResult from "../components/backoffice/SetEditResult.vue";
import NotFound from "../components/backoffice/NotFound.vue";
Vue.use(VueRouter);

function is_user_logged() {
  var z = localStorage.getItem("token");
  if (z != null) {
    return true;
  } else {
    return false;
  }
}
const routes = [
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/",
    component: () => import("../views/ClientMain.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../components/client/ClientHome.vue"),
      },
      {
        path: "/about-us",
        name: "About Us",
        component: () => import("../components/client/ClientAboutUs.vue"),
      },
      {
        path: "/contact-us",
        name: "Contact Us",
        component: () => import("../components/client/ClientContactUs.vue"),
      },
      {
        path: "/responsible-gaming",
        name: "Responsible Gaming",
        component: () =>
          import("../components/client/ClientResponsibleGaming.vue"),
      },
      {
        path: "/live-draw",
        name: "Live Draw",
        component: () => import("../components/client/ClientLiveDraw.vue"),
      },

      {
        path: "/test",
        name: "test",
        component: () => import("../components/test.vue"),
      },
      {
        path: "/result/:hari",
        name: "Result",
        component: () => import("../components/client/ClientResult.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/AdminMain.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
      },
      {
        path: "web",
        name: "Web Content",
        component: SetWebsite,
      },
      {
        path: "time",
        name: "Web Time",
        component: SetWaktu,
      },
      {
        path: "addResult",
        name: "Tambah result",
        component: SetTambahResult,
      },
      {
        path: "dataResult",
        name: "Data Result",
        component: SetDataResult,
      },
      {
        path: "editResult/:id",
        name: "Edit Result",
        component: SetEditResult,
        props: true,
      },
      {
        path: "result",
        name: "Result",
        component: SetResult,
      },
      {
        path: "admin",
        name: "Admin",
        component: SetAdmin,
      },
      
      {
        path: "change-password",
        name: "Change Password",
        component: () => import("../components/backoffice/SetChangePassword.vue"),
      },
    ],
    beforeEnter(to, from, next) {
      // => using before enter
      if (to.name == "Login") {
        next();
      } else {
        if (is_user_logged()) {
          next();
        } else {
          next({ name: "Login" }); //=> redirecting to another page
        }
      }
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/backoffice/AdminLogin.vue"),
  },
  {
    path: "*",
    name: "404 - Not Found",
    component: NotFound,
  },
];
const router = new VueRouter({
  routes,
});

export default router;
