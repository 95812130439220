<template>
  <div class="p-d-flex p-jc-center">
    <div class="round">{{getNumber(value,0)}}</div>
    <div class="round">{{getNumber(value,1)}}</div>
    <div class="round">{{getNumber(value,2)}}</div>
    <div class="round">{{getNumber(value,3)}}</div>
    <div class="round">{{getNumber(value,4)}}</div>
    <div class="round">{{getNumber(value,5)}}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: String
  },
    methods:{
        getNumber(str,index){
            return str.charAt(index)
        }
    }
};
</script>

<style lang="scss">
.round {
  border-radius: 50%;
  background-color: rgb(59, 116, 182);
  color: white;
  height: 2rem;
  width: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-family: "Nasalization";
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
</style>