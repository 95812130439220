<template>
  <div>
    <h2>Edit Result</h2>
    <div class="p-grid box p-text-left">
      <div class="p-col">
        <div>Draw No. {{ id }}</div>
      </div>

      <div class="p-col">
        <div>Date : {{ getNow() }}</div>
      </div>
    </div>

    <divider />

    <!--prize 1-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-text-center">Prize 1</div>
      </div>
      <div class="p-col">
        <InputText class="p-text-center" v-model="draw_prize1" />
      </div>
    </div>

    <!--prize 2-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-text-center">Prize 2</div>
      </div>
      <div class="p-col">
        <InputText class="p-text-center" v-model="draw_prize2" />
      </div>
    </div>

    <!--prize 3-->
    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="box p-jc-center p-ai-center p-text-center">
          <div>Prize 3</div>
        </div>
      </div>
      <div class="p-col">
        <InputText class="p-text-center" v-model="draw_prize3" />
      </div>
    </div>

    <!--Started-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center p-ai-center">Started</div>
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started1" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started2" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started3" />
      </div>

      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started4" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started5" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_started6" />
      </div>
    </div>

    <!--consolation-->
    <div class="p-grid p-fluid p-my-3" style="border: 1px #aaa solid">
      <div class="p-col-12">
        <div class="box p-text-center">Consolation</div>
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation1" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation2" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation3" />
      </div>

      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation4" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation5" />
      </div>
      <div class="p-col-4">
        <InputText class="p-text-center" v-model="draw_consolation6" />
      </div>
    </div>
    <div class="p-d-flex p-jc-center">
      <Button label="UPDATE" @click="updateResult" />
    </div>
  </div>
</template>

<script>
export default {
  props: { id: String },
  data() {
    return {
      draw_prize1: "",
      draw_prize2: "",
      draw_prize3: "",
      draw_started1: "",
      draw_started2: "",
      draw_started3: "",
      draw_started4: "",
      draw_started5: "",
      draw_started6: "",
      draw_consolation1: "",
      draw_consolation2: "",
      draw_consolation3: "",
      draw_consolation4: "",
      draw_consolation5: "",
      draw_consolation6: "",
      draw_data: [],
    };
  },
  methods: {
    async getResult() {
      await this.$http.get("/result/" + this.id).then((r) => {
        this.draw_data = r.data[0];
        this.setResult();
      });
    },
    async updateResult() {
      let formData = new FormData();
      formData.append("draw_prize1", this.draw_prize1);
      formData.append("draw_prize2", this.draw_prize2);
      formData.append("draw_prize3", this.draw_prize3);
      formData.append("draw_started1", this.draw_started1);
      formData.append("draw_started2", this.draw_started2);
      formData.append("draw_started3", this.draw_started3);
      formData.append("draw_started4", this.draw_started4);
      formData.append("draw_started5", this.draw_started5);
      formData.append("draw_started6", this.draw_started6);
      formData.append("draw_consolation1", this.draw_consolation1);
      formData.append("draw_consolation2", this.draw_consolation2);
      formData.append("draw_consolation3", this.draw_consolation3);
      formData.append("draw_consolation4", this.draw_consolation4);
      formData.append("draw_consolation5", this.draw_consolation5);
      formData.append("draw_consolation6", this.draw_consolation6);

      await this.$http
        .put("/result/" + this.id, {
          draw_prize1: this.draw_prize1,
          draw_prize2: this.draw_prize2,
          draw_prize3: this.draw_prize3,
          draw_started1: this.draw_started1,
          draw_started2: this.draw_started2,
          draw_started3: this.draw_started3,
          draw_started4: this.draw_started4,
          draw_started5: this.draw_started5,
          draw_started6: this.draw_started6,
          draw_consolation1: this.draw_consolation1,
          draw_consolation2: this.draw_consolation2,
          draw_consolation3: this.draw_consolation3,
          draw_consolation4: this.draw_consolation4,
          draw_consolation5: this.draw_consolation5,
          draw_consolation6: this.draw_consolation6,
        })
        .then((r) => {
          console.log("response");
          console.log(r);
          if (r.status == 200) {
            this.$router.go(-1);
          }
        });
    },
    getNow() {
      var dt = new Date();
      return dt.toDateString();
    },
    setResult() {
      let data = this.draw_data;
      this.draw_prize1 = data.draw_prize1;
      this.draw_prize2 = data.draw_prize2;
      this.draw_prize3 = data.draw_prize3;
      this.draw_started1 = data.draw_started1;
      this.draw_started2 = data.draw_started2;
      this.draw_started3 = data.draw_started3;
      this.draw_started4 = data.draw_started4;
      this.draw_started5 = data.draw_started5;
      this.draw_started6 = data.draw_started6;
      this.draw_consolation1 = data.draw_consolation1;
      this.draw_consolation2 = data.draw_consolation2;
      this.draw_consolation3 = data.draw_consolation3;
      this.draw_consolation4 = data.draw_consolation4;
      this.draw_consolation5 = data.draw_consolation5;
      this.draw_consolation6 = data.draw_consolation6;
    },
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    this.getResult();
  },
};
</script>

<style>
</style>