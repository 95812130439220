import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    username: "",
    userId: 0,
    acak: false,
    waktu: null,
  },
  mutations: {
    setUserName(state, name) {
      state.username = name;
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setAcak(state, status) {
      state.acak = status;
    },
    setWaktu(state, wkt) {
      state.waktu = wkt;
    },
  },
  getters: {
    getAcak: (state) => {
      return state.acak;
    },
  },
  actions: {},
  modules: {},
});
