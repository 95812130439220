<template>
  <div>
    <p>
      Berikut ini adalah sedikit panduan tata cara Setting
      <strong style="color: blue">LIVEDRAW</strong>.
    </p>
    <ol>
      <li>
        Perhatikan Timezone pada website
      </li>
      <li>
        Timezone pada website
        <strong style="color: green">Bogor Pools</strong> ini, adalah
        <strong style="color: green">Asia/Jakarta</strong>.
      </li>
      <li>
        Apabila Timezone <strong style="color: green">Bogor Pools</strong> tidak
        sama dengan Timezone Asia/Jakarta atau GMT+7,
      </li>
      <li>
        maka ikuti jam server sesuai Timezone dari
        <strong style="color: green">Bogor Pools</strong>.
      </li>
      <li>
        <strong><em>Contoh :</em></strong>
      </li>
      <li>
        <em>
          - Kita asumsikan LIVEDRAW akan dibuka pada jam 07:00:00 WIB
          (Asia/Jakarta) GMT+7.</em
        >
      </li>
      <li>
        <em
          >&nbsp;&nbsp;Pada server mengikuti timezone Asia/Singapore atau GMT+8,
          jarak waktu antara Singapore dengan Jakarta adalah free 1 jam.</em
        >
      </li>
      <li>
        <em
          >&nbsp;&nbsp;Jika di jakarta hari ini jam 07:00:00 WIB, berarti di
          Singapore hari ini jam 08:00:00 Waktu Singapore.</em
        >
      </li>
      <li>
        <em>&nbsp;&nbsp;</em><strong><em>Maka,</em></strong
        ><em> pada menu </em
        ><strong style="color: green"><em>SETTING WAKTU</em></strong
        ><em>
          dibagian WAKTU STANDBY pada form SAMPAI, jam yang kita masukkan adalah
          08:00:00 Waktu Singapore.</em
        >
      </li>
      <li>
        Perhatikan Form
        <strong style="color: blue">Perhitungan Waktu NORMAL</strong> di menu
        <strong style="color: blue">SETTING WAKTU</strong>.
      </li>
    </ol>
    <ul>
      <li class="ql-indent-1">
        <strong style="color: blue">WAKTU STANDBY</strong> :
        <em>adalah jarak waktu penambahan result baru.</em>
      </li>
      <li class="ql-indent-1">
        <strong style="color: blue">Open LIVE RESULT</strong> :
        <em>adalah jarak waktu LIVEDRAW dibuka.</em>
      </li>
      <li class="ql-indent-1">
        <strong style="color: blue">Close LIVE RESULT</strong> :
        <em>adalah jarak waktu LIVEDRAW ditutup.</em>
      </li>
    </ul>
    <ol>
      <li>
        Perhatikan Form
        <strong style="color: blue">Perhitungan Waktu LIVE RESULT</strong> di
        menu <strong style="color: blue">SETTING WAKTU</strong>.
      </li>
    </ol>
    <ul>
      <li class="ql-indent-1">
        <em
          >Ikuti petunjuk pengisian sesuai arah jam yang ada, secara
          terus-menerus dan berurutan.</em
        >
      </li>
    </ul>
    <p><br /></p>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  data() {
    return {
      activeIndex: 0,
      draw_data: [],
    };
  },
  methods: {
    async getResult() {
      await this.$http.get("/result").then((r) => (this.draw_data = r.data));
    },
  },
  mounted() {
    this.getResult();
  },
};
</script>