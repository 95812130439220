<template>
  <div>
    <h2>Setting Konfigurasi Website</h2>
    <hr />
    <div>
      <div v-if="web_data != null">
        <!--Nama Website-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Nama Website
          </label>
          <div class="p-col-12 p-md-10">
            <InputText id="name" type="text" v-model="web_data.web_name" />
          </div>
        </div>
        <Divider />

        <!--Timezone-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Timezone
          </label>
          <div class="p-col-12 p-md-10">
            <InputText id="name" type="text" v-model="web_data.web_timezone" />
          </div>
        </div>
        <Divider />

        <!--GMT-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            GMT
          </label>
          <div class="p-col-12 p-md-10">
            <InputText id="name" type="text" v-model="web_data.web_gmt" />
          </div>
        </div>
        <Divider />

        <!--Email-->
        <div class="p-field p-grid p-fluid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Email
          </label>
          <div class="p-col-12 p-md-10">
            <InputText id="name" type="text" v-model="web_data.web_email" />
          </div>
        </div>
        <Divider />

        <!--Logo-->
        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Logo
          </label>
          <div class="p-col-12 p-md-10">
            <div class="p-mb-1">Ukuran File: 300x150</div>
            <img
              v-if="logo == null"
              height="150px"
              width="300px"
              style="object-fit: contain"
              :src="env + '/image/' + web_data.web_logo"
            />
            <img
              v-else
              height="150px"
              width="300px"
              style="object-fit: contain"
              :src="logo"
            />
            <div class="p-mt-3">
              <input type="file" accept="image/*" @change="logoChange" />
            </div>
          </div>
        </div>
        <Divider />

        <!--Favicon-->
        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Favicon
          </label>
          <div class="p-col-12 p-md-10">
            <div class="p-mb-1">Ukuran File: 150x150</div>
            <img
              v-if="favicon == null"
              height="150px"
              width="150px"
              style="object-fit: contain"
              :src="env + '/image/' + web_data.web_favicon"
            />
            <img
              v-else
              height="150px"
              width="150px"
              style="object-fit: contain"
              :src="favicon"
            />
            <div class="p-mt-3">
              <input type="file" accept="image/*" @change="faviconChange" />
            </div>
          </div>
        </div>
        <Divider />

        <!--Banner-->
        <div class="p-field p-grid">
          <label for="name" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">
            Background
          </label>
          <div class="p-col-12 p-md-10">
            <div class="p-mb-1">Ukuran File: 1280x720</div>
            <img
              v-if="banner == null"
              height="200px"
              style="object-fit: cover"
              width="360px"
              :src="env + '/image/' + web_data.web_banner"
            />
            <img
              v-else
              height="200px"
              style="object-fit: cover"
              width="360px"
              :src="banner"
            />
            <div class="p-mt-3">
              <input type="file" accept="image/*" @change="bannerChange" />
            </div>
          </div>
        </div>
        <Divider />
      </div>
      <div class="p-d-flex p-jc-end">
        <Button label="UPDATE" @click="updateWeb" />
      </div>
      <!-- <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> -->
    </div>
    <Toast />
  </div>
</template>

<script>
export default {
  data() {
    return {
      web_data: null,
      env: process.env.VUE_APP_BACKEND_API,
      banner: null,
      banner_file: null,
      logo: null,
      logo_file: null,
      favicon: null,
      favicon_file: null,
    };
  },
  methods: {
    async getImage() {
      var fav;
      this.$http.get("/image/bogorpools.png").then((r) => (fav = r));
      return fav.data;
    },
    async getFavicon() {
      var fav;
      await this.$http.get("/image/bogorpools.png").then((r) => (fav = r));
      console.debug(fav.data);
      return fav;
    },
    async getWebContent() {
      await this.$http.get("/web").then((r) => {
        this.web_data = r.data;
        const favicon = document.getElementById("favicon");
        favicon.href = this.env + "/image/" + r.data.web_favicon;
      });
    },
    async updateWeb() {
      console.log(this.web_data);
      let fdata = new FormData();
      fdata.append("web_gmt", this.web_data.web_gmt);
      fdata.append("web_name", this.web_data.web_name);
      fdata.append("web_timezone", this.web_data.web_timezone);
      fdata.append("web_email", this.web_data.web_email);
      if (this.logo_file != null) {
        fdata.append("web_logo", this.logo_file);
      }

      if (this.favicon_file != null) {
        fdata.append("web_favicon", this.favicon_file);
      }

      if (this.banner_file != null) {
        fdata.append("banner", this.banner_file);
      }
      console.log(fdata.get("web_banner"));
      await this.$http({
        method: "post",
        url: "/web",
        data: fdata,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((r) => {
        console.log(r);
        if (r.statusText == "OK") {
          this.$toast.add({
            severity: "info",
            summary: "Confirmed",
            detail: "Web Updated",
            life: 3000,
          });
        }
      });
    },
    bannerChange(e) {
      this.banner_file = e.target.files[0];
      this.banner = URL.createObjectURL(e.target.files[0]);
    },
    faviconChange(e) {
      this.favicon_file = e.target.files[0];
      this.favicon = URL.createObjectURL(e.target.files[0]);
    },
    logoChange(e) {
      this.logo_file = e.target.files[0];
      this.logo = URL.createObjectURL(e.target.files[0]);
    },
  },
  mounted() {
    this.$http.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    this.getWebContent();
  },
};
</script>

<style>
</style>