import Vue from "vue";
import PrimeVue from "primevue/config";
import axios from "axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//primevue
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./global.scss";

//prime vue service
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

Vue.use(ToastService);
Vue.use(ConfirmationService);
Vue.use(PrimeVue, { ripple: true });

/*
 * PrimeVue Component import & registration
 */
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import ConfirmDialog from "primevue/confirmdialog";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import Password from "primevue/password";
import Skeleton from "primevue/skeleton";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import Editor from "primevue/editor";

Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("InputText", InputText);
Vue.component("InputMask", InputMask);
Vue.component("Button", Button);
Vue.component("Calendar", Calendar);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("Checkbox", Checkbox);
Vue.component("Dialog", Dialog);
Vue.component("Divider", Divider);
Vue.component("Dropdown", Dropdown);
Vue.component("Editor", Editor);
Vue.component("Password", Password);
Vue.component("Skeleton", Skeleton);
Vue.component("Textarea", Textarea);
Vue.component("Toast", Toast);

Vue.config.productionTip = false;
const base = axios.create({
  // baseURL: "http://192.168.1.10:89/api",
  baseURL: process.env.VUE_APP_BACKEND_API,
});

Vue.prototype.$http = base;

/**
 * Navigation Guard
 */
// router.beforeEach((to, from, next) => {
//   if (to.name !== "Login" && !isAuthenticated) next({ name: "Login" });
//   else next();
// });

new Vue({
  router,
  store,
  axios,
  render: (h) => h(App),
}).$mount("#app");
